var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.tableData.headers,"items":_vm.tableData.data,"search":_vm.search,"items-per-page":-1,"hide-default-footer":"","item-key":_vm.itemKey},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),(_vm.showNew)?_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","color":"primary","data-qa":"tableNew"},on:{"click":function($event){return _vm.$emit('add')}}},[_c('v-icon',[_vm._v("$add")])],1):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showSelect && item !== _vm.selected)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.actionsDisabled,"data-qa":"tableOpen"},on:{"click":function($event){return _vm.$emit('select', { item: item, value: true })}}},'v-icon',attrs,false),on),[_vm._v(" $findInPage ")])]}}],null,true)},[_c('span',[_vm._v("Open")])]):_vm._e(),(_vm.showSelect && item === _vm.selected)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.actionsDisabled},on:{"click":function($event){return _vm.$emit('select', { item: item, value: false })}}},'v-icon',attrs,false),on),[_vm._v(" $close ")])]}}],null,true)},[_c('span',[_vm._v("Close")])]):_vm._e(),(_vm.showEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.actionsDisabled},on:{"click":function($event){return _vm.$emit('edit', item)}}},'v-icon',attrs,false),on),[_vm._v(" $edit ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.showExtra)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.actionsDisabled},on:{"click":function($event){return _vm.$emit('extra', item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showExtra.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.showExtra.text))])]):_vm._e(),(_vm.showMemo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.actionsDisabled},on:{"click":function($event){_vm.initialItem = Object.assign({}, item);
							_vm.memoDialog = true;}}},'v-icon',attrs,false),on),[_vm._v(" $assignment ")])]}}],null,true)},[_c('span',[_vm._v("Memo")])]):_vm._e(),(_vm.showDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.actionsDisabled},on:{"click":function($event){return _vm.$emit('delete', item)}}},'v-icon',attrs,false),on),[_vm._v(" $delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()]}},(_vm.showLabelClick)?{key:"item.name",fn:function(ref){
							var item = ref.item;
return [_vm._t("labelClickName",null,{"item":item})]}}:null],null,true)}),_c('v-memo',{attrs:{"dialog":_vm.memoDialog,"initialItem":_vm.initialItem},on:{"save":function($event){_vm.$emit('memo', $event);
			_vm.memoDialog = false;},"cancel":function($event){_vm.memoDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }