














































































































































import Vue from 'vue';
import VMemo from '@/components/memo.vue';

export default Vue.extend({
	name: 'Table',
	components: { VMemo },
	props: {
		title: String,
		tableData: Object,
		showSelect: {
			type: Boolean,
			default: false
		},
		itemKey: {
			type: String,
			default: null
		},
		showNew: {
			type: Boolean,
			default: false
		},
		showEdit: {
			type: Boolean,
			default: false
		},
		showMemo: {
			type: Boolean,
			default: false
		},
		showDelete: {
			type: Boolean,
			default: false
		},
		showLabelClick: {
			type: Boolean,
			default: false
		},
		showExtra: {
			type: Object,
			default: null
		},
		selected: {
			type: Object,
			default: null
		},
		actionsDisabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return { search: '', memoDialog: false, initialItem: {} };
	}
});
