
















































































































import Vue from 'vue';
import vtable from '@/components/table.vue';
import { mapActions } from 'vuex';
import VForm from '@/types/vuetify';
import { CategoryType } from '@/types/sqlite';

export default Vue.extend({
	name: 'CategoryList',
	data(): {
		selectedIndex: number | null;
		dialog: boolean;
		valid: boolean;
		editing: CategoryType | null;
		formCategory: null | string;
		formParentCategory: null | number;
		dialogCode: boolean;
		validCode: boolean;
		formCode: null | string;
	} {
		return {
			selectedIndex: null,
			dialog: false,
			valid: false,
			editing: null,
			formCategory: null,
			formParentCategory: null,
			dialogCode: false,
			validCode: false,
			formCode: null
		};
	},
	components: {
		vtable
	},
	computed: {
		selected(): CategoryType | null {
			return this.selectedIndex !== null && this.categories.data
				? this.categories.data[this.selectedIndex]
				: null;
		},
		categories() {
			return {
				headers: [
					{ value: 'name', text: 'Name' },
					{ value: 'actions', text: 'Actions' }
				],
				data: this.$store.state.projectCurrent.categories.data.filter(
					(v: CategoryType) => v.status === 1
				)
			};
		},
		categoriesWithoutSelf() {
			return [
				{ text: 'no parent category', value: null },
				...this.$store.state.projectCurrent.categories.data
					.filter(
						(v: CategoryType) =>
							v.status === 1 &&
							(this.editing === null || this.editing.catid !== v.catid)
					)
					.map((v: CategoryType) => ({
						...v,
						text: v.name,
						value: v.catid
					}))
			];
		},
		codesByCategory() {
			if (!this.selected) return;
			const codesInsideCategory = this.$store.getters.getCodesByCategoryId(
				this.selected.catid
			);
			return {
				headers: [
					{ value: 'name', text: 'Name' },
					{ value: 'actions', text: 'Actions' }
				],
				data: this.$store.state.projectCurrent.codes.data.filter(
					(v: { status: number; id: number }) =>
						v.status === 1 && codesInsideCategory.includes(v.id)
				)
			};
		},
		codesNotInCategory() {
			if (!this.selected) return;
			const codesInsideCategory = this.$store.getters.getCodesByCategoryId(
				this.selected.catid
			);
			return this.$store.state.projectCurrent.codes.data
				.filter(
					(v: { status: number; id: number }) =>
						v.status === 1 && !codesInsideCategory.includes(v.id)
				)
				.map((v: { name: string; id: number }) => ({
					...v,
					text: v.name,
					value: v.id
				}));
		}
	},
	methods: {
		...mapActions([
			'addCategory',
			'updateCategory',
			'deleteCategory',
			'addCategoryCode',
			'deleteCategoryCode',
			'updateMemoCategory'
		]),
		selectionChange({ item, value }: { item: CategoryType; value: boolean }) {
			this.selectedIndex = value ? this.categories.data.indexOf(item) : null;
		},
		openNewCategory() {
			this.editing = null;
			this.formParentCategory = null;
			if (this.$refs.form) (this.$refs.form as VForm).reset();
			this.dialog = true;
		},
		openEditCategory(item: CategoryType) {
			this.editing = item;
			this.formCategory = this.editing.name;
			this.formParentCategory = this.editing.parentid;
			this.dialog = true;
		},
		save() {
			const item = {
				name: this.formCategory,
				parentid: this.formParentCategory
			};
			if (this.editing) {
				this.updateCategory({ ...this.editing, ...item });
			} else {
				this.addCategory(item);
			}
			this.dialog = false;
		},
		saveCategoryCode() {
			if (!this.formCode || !this.selected) return;
			this.addCategoryCode({ cid: this.formCode, catid: this.selected.catid });
			this.dialogCode = false;
		},
		openCategoryCode() {
			this.dialogCode = true;
		},
		handleDeleteCategoryCode(code: { id: number }) {
			if (!code || !this.selected) return;
			this.deleteCategoryCode({
				cid: code.id,
				catid: this.selected.catid
			});
		}
	}
});
